import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import { Layout, Stack, Main, Sidebar } from '@layout'
import { Box } from 'theme-ui'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageCareers = props => {
  const { t } = useTranslation()
  return (
    <Layout {...props}>
      <Seo title={t('Careers').concat(' - ').concat(t('Build the futuire with us and have fun while doing so.'))} />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="Come work with us."
            subheader='Join us in building some cutting-edge technology that change the world, and have fun while doing so.'
          />
          <Divider />
          <Box>
            - <Trans>No open positions at the moment. We will post new positions here, please check back later.</Trans>
          </Box>
          <Box>
            - <Trans>We always welcome unsolicited applications from persons who want to add value to the team, please send applications to contact@sop-it.de</Trans>
          </Box>
          <Divider space={150} />
        </Main>
        
      </Stack>
    </Layout>
  )
  } 

export default PageCareers

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`